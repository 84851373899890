/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from "react"
import { useLunr } from "react-lunr"
import useInterval from "use-interval"
import { Container, CardSet } from "../components/Sections"
import Header from "../components/Header"

import lunrData from "../../static/search-index.json"

const SearchResults = ({ location }) => {
  const localRef = useRef(null)
  const [query, setQuery] = useState(
    new URLSearchParams(location.search).get("s") || ""
  )

  useEffect(() => {
    localRef.current = new URLSearchParams(location.search).get("s") || ""
  }, [])

  useInterval(() => {
    const xp = new URLSearchParams(location.search).get("s") || ""
    if (xp !== localRef.current) {
      setQuery(xp)
    }
  }, 1000)

  const results = useLunr(query.toLowerCase(), lunrData.index, lunrData.store)
  const posts = results.map(i => ({
    featuredImage: { id: i.imageId },
    ...i,
  }))
  return (
    <div>
      <Header heading="Search Results" subheading={null} />

      <Container className="-mt-20 sm:-mt-24 lg:-mt-32">
        <CardSet showDate={false} posts={posts} />
      </Container>
    </div>
  )
}

export default SearchResults
